import { FC, useState } from 'react';
import FormManagementService from '../../services/form-management.service';
import FormTemplateService from '../../services/formTemplate.service';
import { RootRequestModel } from '../../models/formSchemaRequest/root-request';
import { FormListModel } from '../../models/forms/form-list.model';
import { FormModel } from '../../models/formSchemaRequest/form';
import { convertTemplate } from '../../helpers/mosaic-to-acg-converter/TemplateConverter';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { ChunkFormData } from '../../models/formSchema/chunk-form-data';
import { SectionModel } from '../../models/formSchemaRequest/section';
import { set } from 'react-hook-form';

interface FileUploadModalProps {
  allowedExtensions: string[];
  onClose: () => void;
  title: string;
}

const FileUploadModal: FC<FileUploadModalProps> = ({ allowedExtensions, onClose, title }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isFileValid, setIsFileValid] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileContents, setFileContents] = useState();
  const [sendInChunks, setSendInChunks] = useState(false);
  const tenant = useSelector((state: any) => state.tenants.entities);
  const [addFileUploadSection, setaddFileUploadSection] = useState(false);
  const [formUploadTitle, setFormUploadTitle] = useState('');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setaddFileUploadSection(event.target.checked);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormUploadTitle(event.target.value);
  };

    const addSupportingDocumentsSection = (form: FormModel, title: string) => {
      const newSection: SectionModel = {
        title: 'Supporting Documentation',
        steps: [
          {
            skipToSummaryConditions: null,
            conditions: null,
            entities: [
              {
                type: 'question',
                id: 'hasSupportingDocuments',
                conditions: null,
                entities: null,
              },
            ],
            repeatable: false,
            repeatableTitle: '',
            info: '',
            numberOfNestedConditionals: null,
          },
          {
            skipToSummaryConditions: null,
            conditions: [
              {
                questionId: 'hasSupportingDocuments',
                operator: 0,
                value: 'Y',
              },
            ],
            entities: [
              {
                type: 'fileuploadfield',
                id: 'supportingDocuments',
                conditions: null,
                entities: null,
              },
            ],
            repeatable: true,
            repeatableTitle: 'Do you have another file to upload?',
            info: '',
            numberOfNestedConditionals: null,
          },
        ],
      };
  
      const newQuestions = [
        {
          id: 'hasSupportingDocuments',
          text: 'Do you have any supporting documentation to upload?',
          type: 'radiobuttonfield',
          helper: '',
          validation: null,
          defaultValue: null,
          mandatory: true,
          properties: {
            max: null,
            min: null,
            allowedExtensions: null,
            readonly: false
          },
          options: [
            {
              value: 'Y',
              text: 'Yes',
              declaration: null
            },
            {
              value: 'N',
              text: 'No',
              declaration: null
            }
          ]
        },
        {
          id: 'supportingDocuments',
          text: title,
          type: 'fileuploadfield',
          helper: '',
          validation: null,
          defaultValue: null,
          mandatory: true,
          properties: {
            max: null,
            min: null,
            allowedExtensions: '.pdf,.jpeg',
            readonly: false
          },
          options: []
        }
      ];
  
    if (!form.layout) {
      form.layout = { sections: [] };
    }
  
    form.layout.sections.push(newSection);
    form.questions.push(...newQuestions);
  };

  const CHUNK_SIZE = 400 * 1024;

  const uploadInChunks = async (serializedObject: string, id: string) => {
    const totalChunks = Math.ceil(serializedObject.length / CHUNK_SIZE);
    let currentChunk = 0;

    while (currentChunk < totalChunks) {
      const start = currentChunk * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, serializedObject.length);

      const chunk = serializedObject.slice(start, end);

      const formData: ChunkFormData = { formID: id, chunk: '', chunkNumber: '', totalChunks: '' };
      formData.chunk = chunk;
      formData.chunkNumber = currentChunk.toString();
      formData.totalChunks = totalChunks.toString();

      try {
        await FormTemplateService.uploadChunk(formData);
      } catch (error) {
        console.error(`Error uploading chunk ${currentChunk + 1}:`, error);
        throw error;
      }

      currentChunk++;
    }
  };

  const uploadSchema = async (json?: FormModel) => {
    const schemaGuid = uuidv4();
    if (!json) {
      toast.error('There was an error converting the file');
      return;
    }
    const rootModel: RootRequestModel = { form: json };
    rootModel.form.id = schemaGuid;

    if (addFileUploadSection) {
      addSupportingDocumentsSection(rootModel.form, formUploadTitle);
    }

    try {
      const formListItem: FormListModel = {
        schemaId: schemaGuid,
        tenantId: tenant.id,
        schemaName: rootModel.form.name,
      };
      if (sendInChunks) {
        await Promise.all([
          FormManagementService.post(formListItem),
          uploadInChunks(JSON.stringify(rootModel), schemaGuid),
        ]);
      } else {
        await Promise.all([
          FormManagementService.post(formListItem),
          FormTemplateService.post(rootModel),
        ]);
      }
      toast.success('File successfully uploaded');

    } catch (error) {
      toast.error('An error occurred when uploading the schema');
    }
    setFormUploadTitle('');
    setaddFileUploadSection(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        setErrorMessage(null);
        setIsFileValid(true);
        setSelectedFile(file);
        if (file.size <= 409600) setSendInChunks(false);
        else setSendInChunks(true);

        const reader = new FileReader();
        reader.onload = (event: any) => {
          const content = event.target.result;
          setFileContents(content);
        };
        reader.readAsText(file);
      } else {
        setErrorMessage(
          `Only the following file extensions are allowed: ${allowedExtensions.join(', ')}`,
        );
        setIsFileValid(false);
        setSelectedFile(null);
      }
    } else {
      setErrorMessage(null);
      setIsFileValid(false);
      setSelectedFile(null);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const convertedTemplate = convertTemplate(fileContents);
      await uploadSchema(convertedTemplate);
      setSelectedFile(null);
      setIsFileValid(false);
      setErrorMessage(null);
      onClose();
    }
  };

  return (
    <div className="govuk-width-container">
      <main className="govuk-main-wrapper govuk-!-padding-2">
        <div className="govuk-form-group govuk-!-margin-top-7">
          <fieldset className="govuk-fieldset">
            <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
              <h1 className="govuk-fieldset__heading">{title} </h1>
            </legend>
            <label className="govuk-label" htmlFor="file-upload-1">
              Upload a file
            </label>
            {errorMessage && (
              <p id="file-upload-1-error" className="govuk-error-message">
                <span className="govuk-visually-hidden">Error:</span> {errorMessage}
              </p>
            )}
            <input
              className="govuk-file-upload"
              id="file-upload-1"
              name="fileUpload1"
              type="file"
              onChange={handleFileChange}
              autoFocus
            />
                        <div className="govuk-checkboxes__item govuk-!-margin-top-4">
              <input
                className="govuk-checkboxes__input"
                id="add-form-upload-section"
                name="addFormUploadSection"
                type="checkbox"
                onChange={handleCheckboxChange}
              />
              <label className="govuk-label govuk-checkboxes__label" htmlFor="add-form-upload-section">
                Add a file upload section to this form
              </label>
            </div>
            {addFileUploadSection && (
              <div className="govuk-form-group">
                <label className="govuk-label" htmlFor="form-upload-title">
                  Title for the file upload section
                </label>
                <input
                  className="govuk-input"
                  id="form-upload-title"
                  name="formUploadTitle"
                  type="text"
                  value={formUploadTitle}
                  onChange={handleTitleChange}
                />
              </div>
            )}
          </fieldset>
        </div>
        <div className="button-container govuk-!-margin-top-4">
          <button
            type="button"
            className="govuk-button govuk-!-margin-right-2"
            onClick={handleUpload}
            disabled={!isFileValid}
          >
            Upload
          </button>
        </div>
      </main>
    </div>
  );
};

export default FileUploadModal;
