import { Suspense, lazy } from 'react';
import LoadingSpinner from '../../components/loader/loader';
import { useSelector } from 'react-redux';
import './privacy-notice.scss';
import { ProductName } from '../../constants/product-info-constants';
import DOMPurify from 'dompurify';

const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));

function PrivacyNotice() {
  const tenant = useSelector((state: any) => state.tenants.entities);
  
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <div id="privacyNotice" className="privacy-notice-container govuk-width-container">
          <main className="govuk-main-wrapper main__container" id="main-content" role="main">
            <div className="govuk-grid-row privacy-background">
              <h1>Access UK Ltd - Privacy and Cookies</h1>
              <hr />
              <main>
                <ul>
                  <li className="external-links list-items">
                    <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice/#about"
                      target="_blank"
                      rel="noreferrer"
                    >
                      About us
                    </a>
                  </li>
                  <li className="external-links">
                    <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice/#why"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Why we have your information
                    </a>
                  </li>
                  <li className="external-links">
                    <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice/#basis"
                      target="_blank"
                      rel="noreferrer"
                    >
                      On what legal basis we have your information
                    </a>
                  </li>
                  <li className="external-links">
                    <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice/#sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sharing your personal data
                    </a>
                  </li>
                  <li className="external-links">
                    <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice/#storage"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Storage and disposal of your personal data
                    </a>
                  </li>
                  <li className="external-links">
                    <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice/#rights"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Your rights
                    </a>
                  </li>
                </ul>
                <h2>Introduction</h2>
                <div>
                  We at the Access Group know it is a big responsibility to protect your personal
                  data. This Privacy Notice is meant to help you understand what information we
                  collect, why we collect it, and how you can update, manage, export, and delete
                  your information. <br />
                  <br />
                  We may from time to time update this Privacy Notice, where we deem it appropriate,
                  we will seek to notify you about the changes, however, we recommend you review
                  this page periodically.
                  <br />
                  <br />
                  <span className="updated-text">
                    This Privacy Notice was last updated on: 29th January 2024
                  </span>
                </div>
                <br />
                <h2>About Us</h2>
                <div>
                  The ‘Access Group’ is made up of numerous legal entities which are wholly owned
                  subsidiaries of The Access Technology Group Limited (company registration
                  05575609). Our primary trading entity is Access UK Ltd (company registration
                  2343760) – the registered address for both is: The Old School, Stratford St. Mary,
                  Colchester, Essex, CO7 6LZ, United Kingdom.
                  <br />
                  <br />
                  For this Privacy Notice, <b>Access UK Ltd is the controller </b>of the personal
                  data which is processed by it as a controller. Other Access Group entities may
                  become a joint controller of your information from time to time.
                  <br />
                  <br />
                  Our Group Data Protection Officer is Danielle Hefford. To contact us regarding our
                  use of personal data, you may email us at Access.DPO@theaccessgroup.com
                  <br />
                  <br /> We have appointed Core Computer Consultants Ltd (registration number:
                  91755) as our EU representative under Article 27, EU GDPR. Our representative can
                  be contacted by email at Core.DPO@theaccessgroup.com
                </div>
                <br />
                <h2>Why we have your information</h2>
                <div>
                  We process personal data to fulfil our contractual obligations to our customers
                  and to otherwise improve and market our solutions.
                  <br />
                  <br /> There are various ways in which we may obtain your personal data. For
                  example, because:
                  <ul>
                    <li>you have contacted us;</li>
                    <li>you visited our website;</li>
                    <li>and/or you’re using our {ProductName} Service.</li>
                  </ul>
                </div>
                <br />
                <h2>On what legal basis we have your information</h2>
                <div>
                  We will only ever process your personal data where we have a lawful basis to do
                  so. We have expanded upon the reasons for us processing your personal data below.
                </div>
                <br />
                <h3>Where you have contacted us</h3>
                <div>
                  If you contact us, for example, for technical support, to inquire about our
                  solutions, or to download some of our content, we will record the contact made and
                  log necessary details of the interaction on our systems. We process any personal
                  data collected via these methods as a <b>controller</b>.<br />
                  <br />
                  The data collected (which may be via telephone recording) may include your first
                  and last name, the email address and telephone number you use for business
                  purposes, your job title, and your employer.
                  <br />
                  <br /> Our lawful basis for processing your personal data in this case is
                  <b> legitimate interests</b>. The legitimate interests pursued are to support us
                  delivering on our contractual obligations (where applicable), internal training,
                  to improve the quality of our services, to make information available to you
                  (including marketing), and or to resolve any conflict resolution (where
                  applicable).
                  <br />
                  <br /> We use third party solutions to record and log the contact made with us;
                  they will therefore process your personal data on our behalf.
                </div>
                <br />
                <h3>Where you visit our website</h3>
                <div>
                  Your personal data, such as your first and last name, telephone number, email
                  address, postal address, job title, employer and any other relevant contact
                  details may be collected by us when you contact us using any of our online forms.
                  The lawful basis for processing this information is legitimate interests. The
                  <b> legitimate interest </b>pursued is to keep you informed about the solutions we
                  offer which we think may be of interest to you (i.e., marketing).
                  <br />
                  <br /> We will also use cookies when you use our website and interact with us via
                  email, to optimise your experience of us and our sites. Please see our{' '}
                  <a href="#cookie-policy">Cookie Policy</a> for more information.
                  <br />
                  <br />
                  Our lawful basis for processing personal data via cookies is <b> consent</b>. The
                  legitimate interest sought is administration, statistical analysis, and
                  enhancement of our website.
                  <br />
                  <br /> All personal data collected by us through our website is done on the basis
                  that we are the <b> controller</b>. <br />
                  <br />
                  Where you choose to share your personal data with us through the mechanisms
                  available via the website, we will process that data using third party solutions.
                  These third parties will process your personal data on our behalf.
                </div>
                <br />
                <h3>Where you’re using {ProductName}</h3>
                <div>
                  Your personal data, that being: your title; first name; surname; job title; email
                  address; and phone number is collected by us in our capacity as a
                  <b> controller</b> to register you as a user of {ProductName}.
                  <br />
                  <br /> The lawful basis of processing this information is <b> contractual</b>, as
                  the processing is necessary for the performance of a contract with you.
                  <br />
                  <br /> The information above is shared with the following partners: Marketo Inc,
                  Salesforce Inc and SendGrid.
                  <br />
                  <br /> We may further process the information collected above to market our
                  solutions to you. Our lawful basis for using your information this way is
                  <b> legitimate interests</b> . Our legitimate interest pursued is to keep you
                  informed about the solutions we offer which we think may be of interest to you.
                </div>
                <br />
                <h2>Further processing of your personal data</h2>
                <div>
                  Where you use one of our solutions, we may derive or create anonymous data and
                  information about your use of that solution. This anonymised data will be further
                  aggregated before being used either by us or a third party nominated by us to
                  improve our products. We may also commercialise this information, for example, to
                  provide insights to third parties.
                  <br />
                  <br /> Our lawful basis for the anonymisation and aggregation process is
                  <b> legitimate interests</b>, and we carry out this processing as a
                  <b> controller</b>. <br />
                  <br />
                  The legitimate interest pursued is statistical analysis to drive informed decision
                  making, for the benefit of both the Access Group and other third parties. <br />
                  <br />
                  Post completion of this anonymisation and aggregation process (which is
                  irreversible), the data is no longer considered personal data for the purpose of
                  applicable data protection legislation.
                </div>
                <br />
                <h2>Sharing your personal data</h2>
                <div>
                  We may have to ad hoc share your personal data with regulators, law enforcement
                  bodies, government agencies, courts or other third parties where we think it’s
                  necessary to comply with applicable laws or regulations, or to exercise, establish
                  or defend our legal rights.
                  <br />
                  <br /> We have also partnered with reputable third parties to provide a
                  best-in-class service offering to you and our wider customer base (as described
                  throughout this privacy notice and or the relevant contracts). Where sharing your
                  personal data with our trusted partners means a transfer to a third country
                  (without an adequacy decision or adequacy regulations, as applicable) is to take
                  place, we will ensure we put in place appropriate safeguards and supplementary
                  measures to ensure an essentially equivalent level of protection (as compared to
                  the UK and EU GDPR) to your personal data will be given.
                  <br />
                  <br /> We may also share your personal data with other members of the Access
                  Group: we will only do this where we have a lawful basis for doing so, for
                  example, <b> fulfilment of contract</b> or <b> legitimate interests</b>. Where the
                  receiver of your personal data is an Access Group entity which is in a third
                  country without an adequacy decision or adequacy regulation (as applicable), the
                  transfer will be safeguarded by, at minimum, an intra-group agreement (which
                  includes recognised model clauses and the UK’s IDTA).
                  <br />
                  <br /> Lastly, we may share your personal data with third parties in connection
                  with a sale of the Access Group (or any part thereof). Where that divested entity
                  becomes a controller of your information, they will supply or otherwise make
                  available to you their relevant privacy notice.
                </div>
                <br />
                <h2>Storage and disposal of your personal data</h2>
                <div>
                  Where we have your personal data acting in our capacity as a <b> controller</b>,
                  we will delete your personal data in accordance with our data retention schedule
                  (available via our&nbsp;
                  <a href="https://access-support.force.com/Support/s/gdpr-hub" target="__blank">
                    Security Portal
                  </a>
                  ), or as otherwise required by data protection legislation: including where you
                  choose to exercise your rights as a data subject (see ‘Your Rights’ section for
                  more information).
                  <br />
                  <br /> Where we have your personal data acting in our capacity as a
                  <b> processor</b>, we will delete your personal data in accordance with the
                  controller’s instruction, or as otherwise required by data protection legislation.
                  If we are required by law to retain any of your personal data, we will inform the
                  controller of this lawful obligation.
                </div>
                <br />
                <h2>Your rights</h2>
                <div>
                  We will process your personal data in accordance the&nbsp;
                  <a
                    // eslint-disable-next-line max-len
                    href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/principles/"
                    target="__blank"
                  >
                    Principles - external link
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    // eslint-disable-next-line max-len
                    href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
                    target="__blank"
                  >
                    Individuals’ Rights - external link
                  </a>
                  &nbsp;of The General Data Protection Regulation (both the EU version (2016/679/EU)
                  and UK retained version thereof).
                </div>
                <h3>Your data protection rights</h3>
                <div>
                  Under data protection law, you have rights including:
                  <ul>
                    <li>
                      <b>Your right of access</b> - You have the right to ask us for copies of your
                      personal information.
                    </li>
                    <li>
                      <b>Your right to rectification</b> - You have the right to ask us to rectify
                      personal information you think is inaccurate. You also have the right to ask
                      us to complete information you think is incomplete. Your right to erasure -
                      You have the right to ask us to erase your personal information in certain
                      circumstances.
                    </li>
                    <li>
                      <b>Your right to restriction of processing</b> - You have the right to ask us
                      to restrict the processing of your personal information in certain
                      circumstances.
                    </li>
                    <li>
                      <b>Your right to object to processing</b> - You have the right to object to
                      the processing of your personal information in certain circumstances.
                    </li>
                    <li>
                      <b>Your right to data portability</b> - You have the right to ask that we
                      transfer the personal information you gave us to another organisation, or to
                      you, in certain circumstances.
                    </li>
                  </ul>
                  To exercise any of these rights, please contact{' '}
                  <a href="mailto:Access.DPO@theaccessgroup.com">Access.DPO@theaccessgroup.com</a>
                  <br />
                  <br />
                  Where we rely on consent as a lawful basis of processing, you may withdraw your
                  consent at any time by emailing us at{' '}
                  <a href="mailto:Access.DPO@theaccessgroup.com">Access.DPO@theaccessgroup.com</a>
                  <br />
                  <br /> <b>Important note:</b> If you feel we have not processed your data in
                  accordance with the Principles and Rights of the individual under GDPR (EU or UK),
                  please contact{' '}
                  <a href="mailto:Access.DPO@theaccessgroup.com">Access.DPO@theaccessgroup.com</a>{' '}
                  (or our EU representative,{' '}
                  <a href="mailto:Core.DPO@theaccessgroup.com">Core.DPO@theaccessgroup.com</a>) for
                  our complaints procedure, or you may raise a complaint with the{' '}
                  <a href="https://ico.org.uk/concerns/">
                    Information Commissioners Office - external link
                  </a>{' '}
                  (or other relevant supervisory authority) but we would like the opportunity to
                  resolve any issues first.
                </div>
                <br />
                <br />
                <hr />
                <div id="cookie-policy"></div>
                <h1>COOKIE POLICY</h1>
                <div>
                  <span className="updated-text">Last updated 29th January 2024</span> <br /> <br />{' '}
                  This Cookie Policy explains how the Access Group (&quot; Company , &quot; we ,
                  &quot; us, &quot; and &quot; our &quot;) uses cookies and similar technologies to
                  recognise you when you visit our websites at www.accesscommunitygateway.co.uk
                  (&quot; Websites&quot;). It explains what these technologies are and why we use
                  them, as well as your rights to control our use of them. <br /> <br />
                  In some cases we may use cookies to collect personal information, or that becomes
                  personal information if we combine it with other information.
                </div>
                <h2>What are cookies?</h2>
                <div>
                  Cookies are small data files that are placed on your computer or mobile device
                  when you visit a website. Cookies are widely used by website owners in order to
                  make their websites work, or to work more efficiently, as well as to provide
                  reporting information.
                  <br /> <br /> Cookies set by us are called &quot;first party cookies&quot;.
                  Cookies set by parties other than us are called &quot;third party cookies&quot;.
                  Third party cookies enable third party features or functionality to be provided on
                  or through the website (e.g., advertising, interactive content, and analytics).
                  The parties that set these third-party cookies can recognize your computer both
                  when it visits the website in question and also when it visits certain other
                  websites.
                </div>
                <h2>Why do we use cookies?</h2>
                <div>
                  We use first and third-party cookies for several reasons. Some cookies are
                  required for technical reasons in order for our Websites to operate, and we refer
                  to these as &quot;essential&quot; or &quot;strictly necessary&quot; cookies. Other
                  cookies also enable us to track and target the interests of our users to enhance
                  the experience on our Websites. Third parties serve cookies through our Websites
                  for advertising, analytics and other purposes. This is described in more detail
                  below.
                </div>
                <br />
                <br />
                <div>
                  The specific types of first and third-party cookies served through our Websites
                  and the purposes they perform are described in the table below.
                </div>
                <br />
                <table className="govuk-table responsive-table">
                  <thead className="govuk-table__head">
                    <tr className="govuk-table__row">
                      <th scope="col" className="govuk-table__header">
                        Name
                      </th>
                      <th scope="col" className="govuk-table__header">
                        Purpose
                      </th>
                      <th scope="col" className="govuk-table__header">
                        Type
                      </th>
                      <th scope="col" className="govuk-table__header">
                        First or Third Party
                      </th>
                      <th scope="col" className="govuk-table__header">
                        Expires in
                      </th>
                    </tr>
                  </thead>
                  <tbody className="govuk-table__body">
                    <tr className="govuk-table__row">
                      <th data-th="Name" scope="row" className="govuk-table__header">
                        x-ms-cpim-sso
                      </th>
                      <td data-th="Purpose" className="govuk-table__cell">
                        Used for maintaining the SSO (Single Sign On) session.
                      </td>
                      <td data-th="Type" className="govuk-table__cell">
                        Essential
                      </td>
                      <td data-th="First or Third Party" className="govuk-table__cell">
                        1st
                      </td>
                      <td data-th="Expires in" className="govuk-table__cell">
                        End of session
                      </td>
                    </tr>
                    <tr className="govuk-table__row">
                      <th data-th="Name" scope="row" className="govuk-table__header">
                        x-ms-cpim-csrf
                      </th>
                      <td data-th="Purpose" className="govuk-table__cell">
                        Cross-Site Request Forgery token used for CRSF protection (protection to
                        ensure that the request to load the page was the result of an in-progress
                        authentication).
                      </td>
                      <td data-th="Type" className="govuk-table__cell">
                        Essential
                      </td>
                      <td data-th="First or Third Party" className="govuk-table__cell">
                        1st
                      </td>
                      <td data-th="Expires in" className="govuk-table__cell">
                        End of session
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h2>What do we mean by the &apos;Type&apos;?</h2>
                <div>
                  <u>Essential Website Cookies</u>: These cookies are strictly necessary to provide
                  you with services available through our Websites and to use some of its features,
                  such as access to secure areas.
                  <br />
                  <u>Performance and functionality cookies</u>: These cookies are used to enhance
                  the performance and functionality of our Websites but are non-essential to their
                  use. However, without these cookies, certain functionality (like videos) may
                  become unavailable.
                  <br />
                  <u>Analytics and customisation cookies</u>: These cookies collect information that
                  is used either in aggregate form to help us understand how our Websites are being
                  used or how effective our marketing campaigns are, or to help us customise our
                  Websites for you.
                  <br />
                  <u>Advertising cookies</u> : These cookies are used to make advertising messages
                  more relevant to you. They perform functions like preventing the same ad from
                  continuously reappearing, ensuring that ads are properly displayed for
                  advertisers, and in some cases selecting advertisements that are based on your
                  interests.
                  <br />
                  <u>Social networking cookies </u>: These cookies are used to enable you to share
                  pages and content that you find interesting on our Websites through third party
                  social networking and other websites. These cookies may also be used for
                  advertising purposes.
                  <br />
                  <u>Unclassified cookies</u> : These are cookies that have not yet been
                  categorised. We are in the process of classifying these cookies with the help of
                  their providers.
                </div>
                <h2>How often will you update this Cookie Policy?</h2>
                <div>
                  We may update this Cookie Policy from time to time to reflect, for example,
                  changes to the cookies we use or for other operational, legal or regulatory
                  reasons. Please therefore re-visit this Cookie Policy regularly to stay informed
                  about our use of cookies and related technologies.
                  <br /> <br /> The date at the top of this Cookie Policy indicates when it was last
                  updated.
                </div>
                <h2>Where can I get further information?</h2>
                <div>
                  If you have any questions about our use of cookies or other technologies, please
                  email us at{' '}
                  <a href="mailto:access.dpo@theaccessgroup.com">access.dpo@theaccessgroup.com</a>
                  <br /> <br /> Where relevant, our EU Representative can be contacted at{' '}
                  <a href="mailto:core.dpo@theaccessgroup.com">core.dpo@theaccessgroup.com</a>
                </div>
                {tenant.customPolicy && (
                  <>
                    <br />
                    <br />
                    <hr />
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tenant.customPolicy) }}
                    />
                  </>
                )}
              </main>
            </div>
          </main>
        </div>
      </DashboardLayout>
    </Suspense>
  );
}

export default PrivacyNotice;
